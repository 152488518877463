// src/components/AssignmentSummary.js

import React from 'react';
import { Card, List, Input, Tag, Avatar, Button, Empty, Space, Typography } from 'antd';
import { UserOutlined, CloseOutlined, ProjectOutlined, SearchOutlined } from '@ant-design/icons';

const { Text } = Typography;

const AssignmentSummary = ({
  assignedJobs,
  getAgentById,
  getJobById,
  handleRemoveAssignedJobs,
  searchSummaryText,
  setSearchSummaryText,
  pendingAssignments,
  setIsDrawerVisible,
}) => {
  const filteredSummaryAgents = Object.keys(assignedJobs)
  .map((jobId) => assignedJobs[jobId])
  .filter((agentId, index, self) => self.indexOf(agentId) === index) // Remove duplicates
  .map((agentId) => getAgentById(agentId))
  .filter((agent, index) => {
    if (agent) {
      return agent.name.toLowerCase().includes(searchSummaryText.toLowerCase());
    } else {
      console.log(`Agent not found for agentId: ${Object.keys(assignedJobs)[index]}`);
      return false; // Exclude the invalid agent
    }
  });

  return (
    <Card title="Assignment Summary" style={{ marginTop: '24px' }}>
      <div style={{ marginBottom: '16px' }}>
        <Input
          placeholder="Search by agent name"
          onChange={(e) => setSearchSummaryText(e.target.value)}
          style={{ width: '300px' }}
          allowClear
          prefix={<SearchOutlined />}
        />
      </div>
      {(Object.keys(assignedJobs).length > 0 || pendingAssignments.length > 0) ? (
        <div>
          {/* Pending Assignments Button */}
          <Button
            type="primary"
            onClick={() => setIsDrawerVisible(true)}
            style={{ marginBottom: '16px' }}
          >
            View Pending Assignments ({pendingAssignments.length})
          </Button>

          {/* Assigned Jobs List */}
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={filteredSummaryAgents}
            renderItem={(agent) => {
              const agentJobs = Object.entries(assignedJobs)
                .filter(([, agentId]) => agentId === agent.id)
                .map(([jobId]) => getJobById(jobId));

              if (agentJobs.length === 0) return null;

              return (
                <List.Item key={agent.id}> {/* Add key prop here */}
                  <Card
                    title={
                      <Space>
                        <Avatar icon={<UserOutlined />} />
                        <Text strong>{agent.name}</Text>
                        <Tag color="blue">{agentJobs.length} Jobs</Tag>
                      </Space>
                    }
                    extra={
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={() => handleRemoveAssignedJobs(agent.id)}
                      />
                    }
                    style={{ width: '100%' }}
                  >
                    <List
                      dataSource={agentJobs}
                      renderItem={(job) => (
                        <List.Item key={job.id}> {/* Add key prop here */}
                          <List.Item.Meta
                            avatar={<ProjectOutlined style={{ color: '#1890ff' }} />}
                            title={<div><Text>{job.name}</Text> <Tag color="geekblue">{job.projectName}</Tag></div>}
                          />
                        </List.Item>
                      )}
                      style={{ maxHeight: '200px', overflowY: 'auto' }}
                    />
                  </Card>
                </List.Item>
              );
            }}
          />
        </div>
      ) : (
        <Empty description="No jobs assigned yet" />
      )}
    </Card>
  );
};

export default AssignmentSummary;
