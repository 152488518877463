// src/hooks/useAssignJobsData.js

import { useState, useEffect } from "react";
import { fetchProjectsData, fetchAgentsData } from "../utils/assignJobsApi";

const useAssignJobsData = () => {
  const [projectsData, setProjectsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [initialAssignedJobs, setInitialAssignedJobs] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const projects = await fetchProjectsData();
        const agents = await fetchAgentsData();

        setProjectsData(projects);
        setAgentsData(agents);

        // Build initialAssignedJobs from projects data
        const assignedJobs = {};
        projects.forEach((project) => {
          project.jobs.forEach((job) => {
            if (job.assigned_agent_id) {
              assignedJobs[job.id] = job.assigned_agent_id;
            }
          });
        });

        setInitialAssignedJobs(assignedJobs);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  return { projectsData, agentsData, initialAssignedJobs, loading };
};

export default useAssignJobsData;
