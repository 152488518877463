import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  List,
  Avatar,
  Button,
  Modal,
  Spin,
  message,
  Dropdown,
  Menu,
  Typography,
  Pagination,
  Input,
  Card,
  Space,
  Tag,
} from "antd";
import {
  ExclamationCircleFilled,
  SlidersOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import {
  fetchAllSubordinates,
  resetSubordinatePassword,
  changeSubordinateRole,
} from "../utils/manageSubordinateApi";
import { debounce } from "lodash";
import SelectOption from "../components/common/customSelectOption";

const { confirm } = Modal;
const { Text, Title } = Typography;

const ManageSubordinate = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 12,
    total: 0,
  });

  const [selectedRole, setSelectedRole] = useState();

  const fetchData = async (
    page = 1,
    pageSize = 10,
    keywords = "",
    selectedRole
  ) => {
    try {
      setLoading(true);
      const result = await fetchAllSubordinates(
        page,
        pageSize,
        keywords,
        selectedRole
      );
      setData(result.subordinates || []);
      setPagination((prev) => ({
        ...prev,
        current: page,
        total: result.totalCount || 0,
      }));
    } catch (error) {
      console.error("Failed to fetch data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(
      pagination.current,
      pagination.pageSize,
      searchKeywords,
      selectedRole
    );
  }, [searchKeywords, selectedRole]);

  const handlePageChange = (page) => {
    fetchData(page, pagination.pageSize, searchKeywords);
  };

  const handleSearch = debounce((value) => {
    setSearchKeywords(value);
    fetchData(1, pagination.pageSize, value);
  }, 300);

  const handlePasswordReset = async (subordinate_id, username) => {
    try {
      const response = await resetSubordinatePassword(subordinate_id);
      const regex = /reset to ([a-zA-Z0-9]+) successfully/;
      const match = response.message.match(regex);
      const resetPassword = match ? match[1] : "unknown";

      Modal.success({
        title: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleTwoTone
              twoToneColor="#52c41a"
              style={{ fontSize: "24px", marginRight: "10px" }}
            />
            <span style={{ fontSize: "18px", fontWeight: "bold" }}>
              Password Reset Successful
            </span>
          </div>
        ),
        icon: null,
        width: 600,
        content: (
          <div style={{ textAlign: "center" }}>
            <p>{username}'s password has been reset successfully to:</p>
            <Text
              copyable
              style={{
                fontSize: "16px",
                display: "inline-block",
                padding: "10px",
                background: "#f5f5f5",
                borderRadius: "5px",
                marginBottom: "10px",
              }}
            >
              {resetPassword}
            </Text>
            <p>
              Please relay this password to <strong>{username}</strong> and ask
              them to change their password.
            </p>
          </div>
        ),
      });
    } catch (error) {
      Modal.error({
        title: "Password Reset Failed",
        content: (
          <div style={{ textAlign: "center" }}>
            <p>
              Failed to reset the password for {username}. Please try again
              later.
            </p>
          </div>
        ),
      });
    }
  };

  const showDeleteConfirm = (subordinate_id, username) => {
    confirm({
      title: `Reset password for ${username}?`,
      icon: <ExclamationCircleFilled />,
      content: "This will reset the user's password to a default value.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handlePasswordReset(subordinate_id, username);
      },
      onCancel() {
        console.log("Password reset cancelled.");
      },
      centered: true,
    });
  };

  const handleRoleChange = async (subordinate_id, new_role) => {
    try {
      const response = await changeSubordinateRole(subordinate_id, new_role);

      if (response.success) {
        Modal.success({
          title: "Role Change Successful",
          icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          content: (
            <div style={{ textAlign: "center" }}>
              <p>Success: {response.message}</p>
            </div>
          ),
          onOk() {
            fetchData(pagination.current, pagination.pageSize, searchKeywords);
          },
        });
      } else {
        Modal.error({
          title: "Role Change Failed",
          icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
          content: (
            <div style={{ textAlign: "center" }}>
              <p>{response.message}</p>
              {response.detail && (
                <p>
                  <strong>Details:</strong> {response.detail}
                </p>
              )}
            </div>
          ),
        });
      }
    } catch (error) {
      Modal.error({
        title: "Error",
        icon: <CloseCircleTwoTone twoToneColor="#ff4d4f" />,
        content: (
          <div style={{ textAlign: "center" }}>
            <p>An error occurred while changing the role: {error.message}</p>
          </div>
        ),
      });
    }
  };

  const roleMenu = (subordinate_id, currentRole) => {
    const allRoles = ["admin", "supervisor", "agent"];
    const availableRoles = allRoles.filter((role) => role !== currentRole);

    return (
      <Menu>
        {availableRoles.map((role) => (
          <Menu.Item
            key={role}
            onClick={() => handleRoleChange(subordinate_id, role)}
          >
            {role.charAt(0).toUpperCase() + role.slice(1)}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  useEffect(() => {
    if (data.length > 0 && !selectedUser) {
      setSelectedUser(data[0]);
    }
  }, [data, selectedUser]);

  const onChangeSelectOption = (value) => {
    setSelectedRole(value);
  };

  return (
    <Spin spinning={loading}>
      <Row style={{ background: "#f0f2f5" }}>
        <Col span={10}>
          <div
            style={{
              padding: "20px",
              overflowY: "auto",
            }}
          >
            <Card>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Input
                  placeholder="Search Agent.."
                  onChange={(e) => handleSearch(e.target.value)}
                  style={{ marginBottom: "20px", marginRight: "20px" }}
                />
                <SelectOption onChangeSelectOption={onChangeSelectOption} />
              </div>
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectedUser(item)}
                  >
                    <Space align="center">
                      <Avatar style={{ backgroundColor: "grey" }}>
                        {item.username.charAt(0).toUpperCase()}
                      </Avatar>
                      <span
                        style={{
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        }}
                      >
                        {item.username}
                      </span>
                      {/* Displaying tag for each role */}
                      <Tag color="blue">
                        {item.role.charAt(0).toUpperCase() + item.role.slice(1)}
                      </Tag>
                    </Space>
                  </List.Item>
                )}
              />
              <Pagination
                current={pagination.current}
                total={pagination.total}
                pageSize={pagination.pageSize}
                onChange={handlePageChange}
                style={{ marginTop: "20px", textAlign: "right" }}
              />
            </Card>
          </div>
        </Col>
        <Col span={14}>
          <div
            style={{
              padding: "20px",
              overflowY: "auto",
            }}
          >
            <Card>
              {selectedUser ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Avatar
                      size={64}
                      style={{ backgroundColor: "grey", marginRight: "16px" }}
                    >
                      {selectedUser.username.charAt(0).toUpperCase()}
                    </Avatar>
                    <div>
                      <Title level={4} style={{ margin: 0 }}>
                        {selectedUser.username}
                      </Title>
                      <Tag color="blue">
                        {selectedUser.role.charAt(0).toUpperCase() +
                          selectedUser.role.slice(1)}
                      </Tag>
                    </div>
                  </div>
                  <p>
                    <strong>Created At:</strong>{" "}
                    {new Date(selectedUser.created_at).toLocaleString()}
                  </p>
                  <Space style={{ marginTop: "20px" }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        showDeleteConfirm(
                          selectedUser._id,
                          selectedUser.username
                        )
                      }
                    >
                      <ExclamationCircleOutlined /> Reset Password
                    </Button>
                    <Dropdown
                      overlay={roleMenu(selectedUser._id, selectedUser.role)}
                    >
                      <Button type="primary">
                        <SlidersOutlined /> Change Role
                      </Button>
                    </Dropdown>
                  </Space>
                </>
              ) : (
                <Text type="secondary">Select a user to view details</Text>
              )}
            </Card>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default ManageSubordinate;
