// src/components/ImageNavigator.js
import React from 'react';
import { Col, Typography, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import OCRImagePreview from './OCRImagePreview';
import { API_URL } from '../utils/jobApi';

const ImageNavigator = ({
  currentImagePath,
  jobDetails,
  currentImageIndex,
  handlePrevImage,
  handleNextImage,
}) => {
  return (
    <Col xs={24} md={8} style={{ textAlign: 'center' }}>
  <div style={{ marginTop: '16px' }}>
    <Button
      icon={<LeftOutlined />}
      onClick={handlePrevImage}
      disabled={currentImageIndex === 0}
      style={{ marginRight: '8px' }}
    >
      Previous
    </Button>
    <Button
      icon={<RightOutlined />}
      onClick={handleNextImage}
      disabled={
        !jobDetails.image_paths ||
        currentImageIndex === jobDetails.image_paths.length - 1
      }
    >
      Next
    </Button>
  </div>
  <div style={{ marginTop: '8px' }}>
    Image {currentImageIndex + 1} of{' '}
    {jobDetails.image_paths ? jobDetails.image_paths.length : 0}
  </div>
      {currentImagePath ? (
        <OCRImagePreview
          imageUrl={`${API_URL}/${currentImagePath}`}
          jobName={jobDetails.name}
          image_index={currentImageIndex}
        />
      ) : (
        <Typography.Text type="warning">No image available.</Typography.Text>
      )}
    </Col>
  );
};

export default ImageNavigator;
