// src/components/JobTable.js

import React from "react";
import { Table, Tag, Button, Popconfirm, Tooltip, Spin } from "antd";
import {
  ProjectOutlined,
  ThunderboltOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  TagsOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const JobActions = ({ jobId, record, onExecute, onDelete }) => {
  const isExecutable =
    record.status === "pending" || record.status === "failed";

  return (
    <>
      <Tooltip
        title={
          isExecutable
            ? "Execute this job"
            : "Job cannot be executed unless it is pending or failed"
        }
      >
        <Button
          type="primary"
          onClick={() => onExecute(jobId)}
          disabled={!isExecutable}
          style={{ marginRight: "8px" }}
        >
          Execute
        </Button>
      </Tooltip>

      <Tooltip title="Delete this job">
        <Popconfirm
          title="Are you sure you want to delete this job?"
          onConfirm={() => onDelete(jobId)}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" danger icon={<DeleteOutlined />} />
        </Popconfirm>
      </Tooltip>
    </>
  );
};

const JobTable = ({
  projectId,
  jobs,
  loading,
  handleActionClick,
  handleDeleteClick,
}) => {
  // const navigate = useNavigate();

  // Render status with icons and tooltips
  const renderStatus = (status) => {
    let statusInfo = {
      text: status,
      color: "default",
      icon: null,
      tooltip: "",
    };

    switch (status) {
      case "pending":
        statusInfo = {
          text: "Pending",
          color: "warning",
          icon: <ExclamationCircleOutlined />,
          tooltip: "Job is pending and awaiting execution.",
        };
        break;
      case "in-progress":
        statusInfo = {
          text: "In Progress",
          color: "processing",
          icon: <SyncOutlined spin style={{ color: "blue" }} />,
          tooltip: "Job is currently being processed.",
        };
        break;
      case "completed":
        statusInfo = {
          text: "Completed",
          color: "success",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          tooltip: "Job has been completed successfully.",
        };
        break;
      case "failed":
        statusInfo = {
          text: "Failed",
          color: "error",
          icon: <CloseCircleOutlined style={{ color: "red" }} />,
          tooltip: "Job execution failed. Please check the logs.",
        };
        break;
      default:
        statusInfo = {
          text: status,
          color: "default",
          icon: null,
          tooltip: "",
        };
        break;
    }

    return (
      <Tooltip title={statusInfo.tooltip}>
        {/* <Tag icon= color={statusInfo.color}> */}
        {statusInfo.icon} {statusInfo.text}
        {/* </Tag> */}
      </Tooltip>
    );
  };

  // Render columns (tags)
  const renderColumns = (columns) => {
    return columns.map((column) => (
      <Tag key={column} icon={<TagsOutlined />} color="blue">
        {column}
      </Tag>
    ));
  };

  // Table columns for job list
  const jobColumns = [
    {
      title: (
        <span>
          <ProjectOutlined /> Job Name
        </span>
      ),
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        const isCompleted = record.status === "completed";
        if (isCompleted) {
          return (
            <Tooltip title="Click to view job details">
              <Link to={`/project/${projectId}/job/${record._id}`}>
                <span style={{ fontWeight: "bold" }}>{text}</span>
              </Link>
            </Tooltip>
          );
        }
        return <span>{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => renderStatus(status),
    },
    {
      title: (
        <span>
          <TagsOutlined /> Columns
        </span>
      ),
      dataIndex: "columns",
      key: "columns",
      render: (columns) => renderColumns(columns),
    },
    {
      title: (
        <span>
          <ThunderboltOutlined style={{ color: "blue" }} /> Actions
        </span>
      ),
      dataIndex: "_id",
      key: "actions",
      render: (jobId, record) => (
        <JobActions
          jobId={jobId}
          record={record}
          onExecute={handleActionClick}
          onDelete={handleDeleteClick}
        />
      ),
    },
  ];

  // const onRowClick = (record) => {
  //   if (record.status === 'completed') {
  //     navigate(`/project/${projectId}/job/${record._id}`);
  //   }
  // };

  return (
    <Spin spinning={loading}>
      <Table
        columns={jobColumns}
        dataSource={jobs}
        rowKey={(record) => record._id}
        // onRow={(record) => ({
        // onClick: () => onRowClick(record),
        // style: { cursor: record.status === 'completed' ? 'pointer' : 'default' },
        // })}
      />
    </Spin>
  );
};

export default JobTable;
