export const roleOptions = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "supervisor",
    label: "Supervisor",
  },
  {
    value: "agent",
    label: "Agent",
  },
];
