// src/utils/api.js

import axios from "axios";
import { API_URL } from "../utils/jobApi";
import { getAccessToken } from "./auth"; // Ensure this function is correctly implemented

//funtion to get tenant id
export const getTenantId = async (slug) => {
  try {
    const response = await axios.get(
      `${API_URL}/get_tenant_id`,
      { params: { slug } },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      }
    );
    return {
      tenant_id: response.data.tenant_id,
      tenant_name: response.data.tenant_name,
    };
  } catch (error) {
    console.error("Error fetching tenant ID:", error);
    if (error.response && error.response.status === 404) {
      console.error("Tenant not found");
    }
  }
};
// Function to register an agent
export const registerAgent = async ({ username, role, password, token }) => {
  try {
    const response = await axios.post(`${API_URL}/agents/register`, {
      username,
      role,
      password,
      token, // Token received from the invitation URL
    });

    return response.data; // Expecting { msg: "...", success: true/false }
  } catch (error) {
    console.error("Registration error:", error);
    throw error;
  }
};
export const fetchRegisteredData = async (imagePath, jobName) => {
  const token = getAccessToken();
  const dataVerify = { imagePath, jobName };

  const response = await axios.post(
    `${API_URL}/get_registered_data`,
    dataVerify,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data; // Return the fetched data
};
