import React from "react";
import { Select } from "antd";
import { roleOptions } from "../../helpers/rolesOption";
const SelectOption = ({ onChangeSelectOption }) => (
  <Select
    showSearch
    allowClear
    placeholder="Choose Role"
    options={roleOptions}
    onChange={onChangeSelectOption}
    style={{
      width: "200px",
    }}
  />
);
export default SelectOption;
