import { getAccessToken } from './auth';
import { API_URL } from '../utils/jobApi';

export const changePassword = async (old_password, new_password) => {
  try {
    const token = getAccessToken();
    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await fetch(`${API_URL}/users/change_password`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ old_password, new_password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to change password.');
    }

    const data = await response.json();
    console.log('Response:', data);
    return data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};
