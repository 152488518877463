import React, { useState, useEffect } from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { changePassword } from "../utils/changePassword"; // Adjust the path as per your project structure

// ChangePasswordModal Component
const App = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [previousPath, setPreviousPath] = useState('/dashboard'); // Default fallback route
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== "/change-password") {
      setPreviousPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/change-password") {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [location]);

  const handlePasswordChange = async (values) => {
    try {
      const { old_password, new_password } = values;
      await changePassword(old_password, new_password);
      message.success("Password changed successfully!");
      
      // Navigate to the previous path
      navigate(previousPath);
    } catch (error) {
      message.error(error.message || "An error occurred while changing the password.");
    }
  };

  const closeModal = () => {
    // Navigate to the previous path when modal is closed
    navigate(previousPath);
  };

  return (
    <div>
      {/* Modal for Change Password */}
      <ChangePasswordModal 
        isOpen={isModalVisible} 
        onClose={closeModal}
        onSubmit={handlePasswordChange}
      />
    </div>
  );
};

// ChangePasswordModal Component
const ChangePasswordModal = ({ isOpen, onClose, onSubmit }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await onSubmit(values);
      form.resetFields();
      onClose();
    } catch (error) {
      // Error handling is done in the parent component
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      // title="Change Password"
      visible={isOpen}
      onCancel={onClose}
      footer={null} // Remove default footer to use custom footer
      width={350}
      style={{ 
        textAlign: "center",
        top:100
       }}
      bodyStyle={{ 
        height:"400px"
       }}
    >
      <div style={{ textAlign: "center", marginBottom: "32px" }}>
      <img
      src="/favicon-black.png"
      alt="Next AI"
      style={{ height: "80px", marginBottom: "16px" }}
      />
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[{ required: true, message: "Please input your old password!" }]}
        >
          <Input.Password placeholder="Enter your old password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password
            placeholder="Enter a strong new password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={submitting}
            size="large"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default App;