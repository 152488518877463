import React, { useState } from "react";
import { Modal, Form, Input, message, Button } from "antd";
import { changePassword } from "../utils/changePassword";

// ChangePasswordModal Component
const ChangePasswordModal = ({
  changePasswordModalOpen,
  setChangePasswordModalOpen,
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await handlePasswordChange(values);
      form.resetFields();
      setChangePasswordModalOpen(false);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const handlePasswordChange = async (values) => {
    try {
      const { old_password, new_password } = values;
      await changePassword(old_password, new_password);
      message.success("Password changed successfully!");
      setChangePasswordModalOpen(false);
    } catch (error) {
      message.error(
        error.message || "An error occurred while changing the password."
      );
    }
  };

  return (
    <Modal
      visible={changePasswordModalOpen}
      onCancel={() => setChangePasswordModalOpen(false)}
      footer={null} // Remove default footer to use custom footer
      width={350}
      style={{
        textAlign: "center",
        top: 100,
      }}
      bodyStyle={{
        height: "325px",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "32px", display:"flex", justifyContent:"center", alignitems:"center" }}>
        <h2>Change Password</h2>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off"
        display="flex"
        justifyContent="center"
        alignitems="center"
      >
        <Form.Item
          label="Old Password"
          name="old_password"
          rules={[
            { required: true, message: "Please input your old password!" },
          ]}
        >
          <Input.Password placeholder="Enter your old password" />
        </Form.Item>
        <Form.Item
          label="New Password"
          name="new_password"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password placeholder="Enter a strong new password" />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={submitting}
            size="large"
          >
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
