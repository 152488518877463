// src/hooks/useAssignJobsLogic.js

import { useState, useCallback, useMemo, useEffect } from 'react';
import { message, Modal, Tag } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { assignAgentToJob, unassignAgentFromJobs } from '../utils/assignJobsApi';
import { getAllJobs, getAgentById, getJobById } from '../utils/assignJobsUtils';

const { confirm } = Modal;

const useAssignJobsLogic = (projectsData, agentsData, initialAssignedJobs) => {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [pendingAssignments, setPendingAssignments] = useState([]);
  const [assignedJobs, setAssignedJobs] = useState({});
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [searchJobText, setSearchJobText] = useState('');
  const [searchAgentText, setSearchAgentText] = useState('');
  const [searchSummaryText, setSearchSummaryText] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [verificationFilter, setVerificationFilter] = useState('all');

  useEffect(() => {
    setAssignedJobs(initialAssignedJobs);
  }, [initialAssignedJobs]);

  // Derived data using utility functions
  const allJobs = useMemo(() => getAllJobs(projectsData), [projectsData]);

  const getAgent = useCallback((agentId) => getAgentById(agentsData, agentId), [agentsData]);

  const getJob = useCallback((jobId) => getJobById(allJobs, jobId), [allJobs]);

  // Handlers
  const handleProjectSelect = (value) => {
    setSelectedProjects(value);
  };

  const handleVerificationFilterChange = (value) => {
    setVerificationFilter(value);
  };

  const handleJobRowClick = (jobId) => {
    setSelectedJobs((prevJobs) =>
      prevJobs.includes(jobId) ? prevJobs.filter((id) => id !== jobId) : [...prevJobs, jobId]
    );
  };

  const handleRemoveSelectedJob = (jobId) => {
    setSelectedJobs((prev) => prev.filter((id) => id !== jobId));
    message.info('Job has been deselected.');
  };

  const handleAgentSelect = (agentId) => {
    setSelectedAgentId(agentId);
  };

  const handleAssignJobs = () => {
    if (!selectedAgentId || selectedJobs.length === 0) {
      message.warning('Please select at least one job and an agent to assign.');
      return;
    }

    // Prepare assignments
    const newAssignments = selectedJobs.map((jobId) => ({
      jobId: String(jobId), // Ensure jobId is a string
      agentId: String(selectedAgentId), // Ensure agentId is a string
    }));

    // Add to pending assignments
    setPendingAssignments((prev) => {
        // Prevent duplicate assignments (optional)
        const uniqueNewAssignments = newAssignments.filter(
          (newAssignment) =>
            !prev.some(
              (existing) =>
                existing.jobId === newAssignment.jobId &&
                existing.agentId === newAssignment.agentId
            )
        );
    
        return [...prev, ...uniqueNewAssignments];
      });
    

    // Reset selected jobs and agent
    setSelectedJobs([]);
    setSelectedAgentId(null);

    // Open the Drawer to show pending assignments
    setIsDrawerVisible(true);

    message.success('Selected jobs are pending confirmation to be assigned.');
  };

  const handleConfirmAssignments = () => {
    if (pendingAssignments.length === 0) {
      message.warning('There are no pending assignments to confirm.');
      return;
    }

    console.log('Pending Assignments:', pendingAssignments);

    confirm({
      title: 'Confirm Assignment',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to confirm these assignments?',
      onOk: async () => {
        try {
          // Assign jobs to agents via API calls
          for (const assignment of pendingAssignments) {
            const { jobId, agentId } = assignment;

            console.log('Processing Assignment:', assignment);

            const job = getJob(jobId);
            const agent = getAgent(agentId);

            if (job && agent) {
              console.log(`Assigning jobId: ${jobId}, agentUsername: ${agentId}`);
              await assignAgentToJob(jobId,agentId );
            } else {
              console.error(`Job or Agent not found for jobId: ${jobId}, agentId: ${agentId}`);
            }
          }

          // Update assignedJobs state
          const newAssignedJobs = { ...assignedJobs };
          pendingAssignments.forEach(({ jobId, agentId }) => {
            newAssignedJobs[jobId] = agentId;
          });
          setAssignedJobs(newAssignedJobs);

          // Clear pending assignments and close Drawer
          setPendingAssignments([]);
          setIsDrawerVisible(false);

          message.success('All pending assignments have been confirmed.');
        } catch (error) {
          console.error('Error confirming assignments:', error);
          message.error('An error occurred while confirming assignments.');
        }
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  const handleRemovePendingAssignment = (jobId) => {
    setPendingAssignments((prev) => prev.filter((assignment) => assignment.jobId !== jobId));
    message.info('Pending assignment has been removed.');
  };

  const handleRemoveAssignedJobs = (agentId) => {
    confirm({
      title: 'Are you sure you want to remove all assignments for this agent?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action will unassign all jobs from the selected agent.',
      onOk: async () => {  // Make this function async to handle the API call
        try {
          // Call the backend API to unassign all jobs from the agent
          const response = await unassignAgentFromJobs(agentId);

          // Update the frontend state
          setAssignedJobs((prev) => {
            const updated = { ...prev };
            Object.entries(updated).forEach(([jobId, aId]) => {
              if (aId === agentId) {
                delete updated[jobId];
              }
            });
            return updated;
          });

          message.success('All assignments for the selected agent have been removed.');
        } catch (error) {
          // Handle errors appropriately
          if (error.response) {
            // Server responded with a status other than 2xx
            message.error(`Error: ${error.response.data.detail || 'Failed to remove assignments.'}`);
          } else {
            // Other errors
            message.error(`Error: ${error.message}`);
          }
        }
      },
      onCancel() {
        // Do nothing on cancel
      },
    });
  };

  // Debounced search handler for jobs
  const debouncedSetSearchJobText = useMemo(
    () =>
      debounce((value) => {
        setSearchJobText(value);
      }, 300),
    []
  );

  const handleJobSearchChange = (e) => {
    debouncedSetSearchJobText(e.target.value);
  };

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSetSearchJobText.cancel();
    };
  }, [debouncedSetSearchJobText]);

  // Filtering logic
  const filteredJobs = useMemo(() => {
    return allJobs.filter((job) => {
      // Filter by selected projects
      const matchesProject =
        selectedProjects.length === 0 || selectedProjects.includes(job.projectId);

      // Filter by verification status
      const matchesVerification =
        verificationFilter === 'all' ||
        (verificationFilter === 'verified' && job.verified) ||
        (verificationFilter === 'unverified' && !job.verified);

      // Filter by search text (job name and agent name)
      const searchText = searchJobText.toLowerCase();
      const agent = getAgent(assignedJobs[job.id]);
      const agentName = agent ? agent.name.toLowerCase() : '';
      const matchesSearch =
        job.name.toLowerCase().includes(searchText) || agentName.includes(searchText);

      return matchesProject && matchesVerification && matchesSearch;
    });
  }, [
    allJobs,
    selectedProjects,
    verificationFilter,
    searchJobText,
    assignedJobs,
    getAgent,
  ]);

  // Job columns for the table
  const jobColumns = useMemo(
    () => [
      {
        title: 'Verified',
        dataIndex: 'verified',
        key: 'verified',
        render: (verified) =>
          verified ? (
            <Tag color="green">Verified</Tag>
          ) : (
            <Tag color="volcano">Unverified</Tag>
          ),
        width: 100,
        filters: [
          { text: 'Verified', value: true },
          { text: 'Unverified', value: false },
        ],
        onFilter: (value, record) => record.verified === value,
      },
      {
        title: 'Job Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Project',
        dataIndex: 'projectName',
        key: 'projectName',
        sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      },
      {
        title: 'Assigned To',
        dataIndex: 'id',
        key: 'assignedTo',
        render: (text, record) => {
          if (assignedJobs[record.id]) {
            const agent = getAgent(assignedJobs[record.id]);
            return <Tag color="geekblue">{agent ? agent.name : 'Unknown'}</Tag>;
          }
          return <Tag color="#4CAF50">Unassigned</Tag>;
        },
        filters: [
          { text: 'Assigned', value: 'assigned' },
          { text: 'Unassigned', value: 'unassigned' },
        ],
        onFilter: (value, record) => {
          if (value === 'assigned') return assignedJobs.hasOwnProperty(record.id);
          if (value === 'unassigned') return !assignedJobs.hasOwnProperty(record.id);
          return true;
        },
      },
    ],
    [assignedJobs, getAgent]
  );

  // Row class name to highlight selected rows
  const rowClassName = (record) =>
    selectedJobs.includes(record.id) ? 'selected-row' : '';

  return {
    selectedProjects,
    handleProjectSelect,
    verificationFilter,
    handleVerificationFilterChange,
    handleJobSearchChange,
    filteredJobs,
    handleJobRowClick,
    rowClassName,
    jobColumns,
    selectedJobs,
    getJob,
    handleRemoveSelectedJob,
    agentsData,
    selectedAgentId,
    handleAgentSelect,
    searchAgentText,
    setSearchAgentText,
    handleAssignJobs,
    assignedJobs,
    getAgent,
    handleRemoveAssignedJobs,
    searchSummaryText,
    setSearchSummaryText,
    pendingAssignments,
    setIsDrawerVisible,
    isDrawerVisible,
    handleRemovePendingAssignment,
    handleConfirmAssignments,
    getJobById: getJob,
    getAgentById: getAgent,
  };
};

export default useAssignJobsLogic;
