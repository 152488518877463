// src/components/EditProjectModal.js
import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import {
  ProjectOutlined,
  FileTextOutlined,
  ProfileOutlined,
  TableOutlined,
  FileDoneOutlined,
  BookOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const EditProjectModal = ({
  isVisible,
  onCancel,
  onFinish,
  // form,
  projects,
  setProjects,
  loading,
  populateProjectDetails,
}) => {
  const [form] = Form.useForm();

  // Dynamic Initial Values
  useEffect(() => {
    if (populateProjectDetails) {
      // Update form values when populateProjectDetails changes
      form.setFieldsValue({
        name: populateProjectDetails?.name,
        description: populateProjectDetails?.description,
        type: populateProjectDetails?.type,
      });
    }
  }, [populateProjectDetails, form]);

  return (
    <Modal
      title="Edit Project"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form} // Connect the form instance
      >
        <Form.Item
          label={
            <span>
              <ProjectOutlined /> Project Name
            </span>
          }
          name="name"
          rules={[{ required: true, message: "Please enter the project name" }]}
        >
          <Input
            value={projects.name}
            onChange={(e) => setProjects({ ...projects, name: e.target.value })}
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              <FileTextOutlined /> Description
            </span>
          }
          name="description"
          rules={[
            { required: true, message: "Please enter the project description" },
          ]}
        >
          <Input.TextArea
            value={projects.description}
            onChange={(e) =>
              setProjects({ ...projects, description: e.target.value })
            }
          />
        </Form.Item>
        <Form.Item
          label={
            <span>
              <ProfileOutlined /> Project Type
            </span>
          }
          name="type"
          rules={[
            { required: true, message: "Please select the project type" },
          ]}
        >
          <Select
            value={projects.type}
            onChange={(value) => setProjects({ ...projects, type: value })}
            placeholder="Select project type"
          >
            <Option value="table">
              <TableOutlined /> Table
            </Option>
            <Option value="receipt">
              <FileDoneOutlined /> Receipt
            </Option>
            <Option value="book_notes">
              <BookOutlined /> Book/Notes
            </Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            icon={<PlusOutlined />}
          >
            Edit Project
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditProjectModal;
