import { useState, useEffect } from 'react';
import { message } from 'antd';
import { getJobsByProjectId } from '../utils/failedJobs'; // Ensure correct import path
import { fetchAllRegisteredJobs } from '../utils/assignJobsApi'; // Assuming this is your job fetching function

const useFailedJobsData = (current_page, pageSize, searchText, selectedDates,sort) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalRecords: 0,
    totalPages: 0,
    currentPage: current_page,
    pageSize: pageSize,
  });

  useEffect(() => {
    const fetchJobData = async () => {
      setLoading(true);
      try {
        // console.log("Selected Dates -> ", selectedDates)
        console.log("Sort -> ", sort)
        const result = await fetchAllRegisteredJobs(current_page, pageSize, searchText, selectedDates,sort);
        // console.log("Result -> ", result)
        setJobs(result.jobs);
        setPagination(prevPagination => ({
          ...prevPagination,
          totalRecords: result.pagination.totalRecords,
          totalPages: result.pagination.totalPages,
          currentPage: result.pagination.currentPage,
          pageSize: result.pagination.pageSize,
        }));
      } catch (error) {
        message.error('Failed to fetch job data.');
        console.error('Detailed error fetching jobs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobData();
  }, [current_page, pageSize, searchText,selectedDates,sort]); // Trigger fetching when page, pageSize, or searchText changes

  const getJobsBySelectedProjectId = async (projectId) => {
    setLoading(true);
    try {
      const fetchedFailedItems = await getJobsByProjectId(projectId);
      setJobs(fetchedFailedItems);
    } catch (error) {
      message.error('Failed to fetch failed items.');
      console.error('Error fetching failed items:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    jobs,
    loading,
    pagination,
    getJobsBySelectedProjectId,
  };
};

export default useFailedJobsData;
