import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Upload,
  Progress,
  message,
  Image,
  Select,
} from "antd";
import {
  ProjectOutlined,
  FileTextOutlined,
  PlusOutlined,
  UploadOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { createJobWithImages, editJobWithImages } from "../utils/jobApi"; // Import the API function

const EditJobModal = ({
  projectId,
  isVisible,
  onCancel,
  onFinish,
  loading,
  editJobId,
  setIsEditJobModalVisible,
  populateJobDetails,
}) => {
  const [fileList, setFileList] = useState([]);
  const [overallProgress, setOverallProgress] = useState(0);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  // Dynamic Initial Values
  useEffect(() => {
    if (populateJobDetails) {
      // Update form values when populateJobDetails changes
      form.setFieldsValue({
        name: populateJobDetails?.name,
        description: populateJobDetails?.description,
        columns: populateJobDetails?.columns,
      });
    }
  }, [populateJobDetails, form]);

  // Generate current date and time string
  const currentDateTime = new Date().toLocaleString();

  // Handle file change for file uploader
  const handleFileChange = ({ fileList }) => {
    setFileList(fileList);
    setOverallProgress(0); // Reset progress when new files are selected
  };

  // Handle preview of image in modal
  const handlePreview = async (file) => {
    setPreviewImage(file.thumbUrl || file.url);
    setPreviewVisible(true);
  };

  const handleSubmit = async (values) => {
    // Prevent double submission
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setOverallProgress(0); // Reset progress at the start of submission

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("columns", values.columns.join(","));

    // Append each image file to the formData
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj);
    });

    try {
      // Use the API function from jobApi.js
      const response = await editJobWithImages(
        editJobId,
        formData,
        (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.round((loaded / total) * 100);
          setOverallProgress(percent);
        }
      );

      // Success Handling
      message.success("Job edited successfully!");
      form.resetFields();
      setFileList([]);
      setOverallProgress(0); // Reset progress after successful upload

      // Pass the new job data back to the parent component
      onFinish(response.job);

      onCancel();
    } catch (error) {
      console.error("Error editing job:", error);
      message.error("Failed to edit job");
      setOverallProgress(0); // Reset progress if upload fails
    } finally {
      setSubmitting(false);
      setIsEditJobModalVisible(false);
    }
  };

  return (
    <Modal
      title="Edit Job"
      visible={isVisible}
      onCancel={() => {
        onCancel();
        setOverallProgress(0); // Reset progress when modal is closed
        setFileList([]); // Clear the file list
        form.resetFields(); // Reset form fields
      }}
      footer={null}
      width="80%"
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        {/* Job Name Field */}
        <Form.Item
          label={
            <span>
              <ProjectOutlined /> Job Name
            </span>
          }
          name="name"
          rules={[{ required: true, message: "Please enter the job name" }]}
        >
          <Input placeholder="Enter job name" />
        </Form.Item>

        {/* Job Description Field */}
        <Form.Item
          label={
            <span>
              <FileTextOutlined /> Description
            </span>
          }
          name="description"
          rules={[
            { required: true, message: "Please enter the job description" },
          ]}
        >
          <Input.TextArea placeholder="Enter job description" />
        </Form.Item>

        {/* Columns/Tags Field */}
        <Form.Item
          label={
            <span>
              <TagsOutlined /> Attributes/Columns
            </span>
          }
          name="columns"
          rules={[
            { required: true, message: "Please specify the columns (tags)" },
          ]}
        >
          <Select
            mode="tags"
            style={{ width: "100%" }}
            placeholder="Enter column names for the batch (e.g., Column1, Column2)"
            tokenSeparators={[","]}
          />
        </Form.Item>

        {/* Image Upload Field */}
        <Form.Item
          label={
            <span>
              <UploadOutlined /> Upload Images
            </span>
          }
          rules={[
            { required: true, message: "Please upload images for the job" },
          ]}
        >
          <Upload
            listType="picture-card"
            fileList={fileList}
            onChange={handleFileChange}
            onPreview={handlePreview}
            beforeUpload={() => false}
            multiple
          >
            {fileList.length < 50 && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Select Images</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        {/* Overall Progress Bar */}
        {fileList.length > 0 && (
          <div style={{ marginBottom: "16px" }}>
            <Progress
              percent={overallProgress}
              status={overallProgress === 100 ? "success" : "active"}
            />
          </div>
        )}

        {/* Submit Button */}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading || submitting}
            icon={<PlusOutlined />}
          >
            Edit Job
          </Button>
        </Form.Item>
      </Form>

      {/* Image Preview Modal */}
      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <Image alt="Preview" src={previewImage} style={{ width: "100%" }} />
      </Modal>
    </Modal>
  );
};

export default EditJobModal;
