import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { getTenantId } from '../utils/api'; // Your API helper function to fetch tenant ID

const NotFoundPage = () => {
  const slug = localStorage.getItem('tenant_slug'); // Get tenant slug from local storage
  const navigate = useNavigate();
  const location = useLocation(); // To get the current URL path
  const currentPath = location.pathname.slice(1); // Extract current path without leading "/"
  const [showHandle, setShowHandle] = useState(true);

  useEffect(() => {
    // Fetch tenant ID based on the current path (slug)
    const fetchTenantId = async () => {
      if (currentPath !== 'login' && currentPath !== 'invitation') {
        try {
          const tenant_id = await getTenantId(currentPath); // Fetch tenant ID using the slug
          if (tenant_id) {
            localStorage.setItem('tenant_id', tenant_id);
            localStorage.setItem('tenant_slug', currentPath); // Store slug in local storage
            navigate(`/${currentPath}/login`); // Navigate to /slug/login
          }
        } catch (error) {
          console.error('Error fetching tenant ID:', error);
        }
      }
    };

    // Blinking handle effect
    const handleBlink = setInterval(() => {
      setShowHandle(prev => !prev);
    }, 500);

    // If the slug exists, try to fetch tenant ID
    fetchTenantId();

    // Cleanup interval
    return () => clearInterval(handleBlink);
  }, [currentPath, navigate]);

  // If tenant_slug exists, directly navigate to /slug/login
  if (slug) {
    return <Navigate to={`/${slug}/login`} replace />;
  }

  return (
    <div className="not-found-container">
      <style>{`
        @import url('https://fonts.googleapis.com/css?family=Roboto+Mono');
        
        .not-found-container {
          width: 100%;
          height: 100vh;
          background-color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Roboto Mono', monospace;
          position: relative;
        }

        .copy-container {
          text-align: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .copy-container p {
          color: #fff;
          font-size: 24px;
          letter-spacing: 0.2px;
          margin: 0;
          position: relative;
        }

        .handle {
          background: #ffe500;
          width: 14px;
          height: 30px;
          position: absolute;
          top: 0;
          left: 0;
          margin-top: 1px;
          opacity: ${showHandle ? 1 : 0};
          transition: opacity 0.3s ease;
        }

        .replay-icon {
          fill: #666;
          width: 20px;
          margin: 15px;
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;
        }

        .replay-icon:hover {
          fill: #888;
        }
      `}</style>
      <div className="copy-container">
        <p>
          404, page not found.
          <span className="handle"></span>
        </p>
      </div>
      <svg 
        className="replay-icon"
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 279.9 297.3"
      >
        <path d="M269.4,162.6c-2.7,66.5-55.6,120.1-121.8,123.9c-77,4.4-141.3-60-136.8-136.9C14.7,81.7,71,27.8,140,27.8
          c1.8,0,3.5,0,5.3,0.1c0.3,0,0.5,0.2,0.5,0.5v15c0,1.5,1.6,2.4,2.9,1.7l35.9-20.7c1.3-0.7,1.3-2.6,0-3.3L148.6,0.3
          c-1.3-0.7-2.9,0.2-2.9,1.7v15c0,0.3-0.2,0.5-0.5,0.5c-1.7-0.1-3.5-0.1-5.2-0.1C63.3,17.3,1,78.9,0,155.4
          C-1,233.8,63.4,298.3,141.9,297.3c74.6-1,135.1-60.2,138-134.3c0.1-3-2.3-5.4-5.3-5.4l0,0C271.8,157.6,269.5,159.8,269.4,162.6z"/>
      </svg>
    </div>
  );
};

export default NotFoundPage;