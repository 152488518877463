// src/utils/assignJobsApi.js

import axios from 'axios';
import { getAccessToken } from './auth';  // Ensure this function is correctly implemented
import { API_URL } from '../utils/jobApi';

// Fetch all projects and their associated jobs
export const fetchProjectsData = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    // Fetch projects
    const projectsResponse = await axios.get(`${API_URL}/projects`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const projects = projectsResponse.data;

    // For each project, fetch its jobs
    const projectsData = await Promise.all(
      projects.map(async (project) => {
        const jobsResponse = await axios.get(`${API_URL}/jobs/${project.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const jobs = jobsResponse.data.map((job) => ({
            id: String(job._id), // Ensure ID is a string
            name: job.name,
            description: job.description,
            verified: job.verified,
            created_at: job.created_at,
            assigned_agent_id: job.assigned_agent_id ? String(job.assigned_agent_id) : null, // Convert to string
            projectId: String(project.id), // Ensure projectId is a string
            projectName: project.name,
          }));

          

        return {
          id: project.id,
          name: project.name,
          jobs,
        };
      })
    );

    return projectsData;
  } catch (error) {
    console.error('Error fetching projects data:', error);
    throw error;
  }
};

// Fetch all agents
export const fetchAgentsData = async () => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.get(`${API_URL}/agents/all`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    const agents = response.data.agents.map((agent) => ({
        id: String(agent._id), // Ensure ID is a string
        username: agent.username,
        name: agent.username,
      }));

    return agents;
  } catch (error) {
    console.error('Error fetching agents data:', error);
    throw error;
  }
};

// API to assign agent to job
export const assignAgentToJob = async (jobId, agent_id) => {
    try {
      const token = getAccessToken();
  
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
      console.log(`API Call - Assigning agentId: ${agent_id} to job: ${jobId}`);
  
      const response = await axios.post(
        `${API_URL}/jobs/${jobId}/assign`,
        null,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            agent_id: agent_id,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error(`Error assigning agent to job ${jobId}:`, error);
      throw error;
    }
  };

  export const unassignAgentFromJobs = async (agentId) => {
    try {
      const token = getAccessToken();
  
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
  
      console.log(`API Call - Unassigning agentId: ${agentId}`);
  
      const response = await axios.post(
        `${API_URL}/agents/${agentId}/unassign`,
        null,  // No body needed
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error(`Error unassigning agent ${agentId}:`, error.response?.data || error.message);
      throw error;
    }
  };
  
  export const fetchAllRegisteredJobs = async (page = 1, pageSize = 10, searchText, selectedDates,sort) => {
    try {
      const token = getAccessToken();
      if (!token) {
        throw new Error('User is not authenticated. Please log in.');
      }
  
      const startDate = selectedDates[0] ??  null;
      const endDate = selectedDates[1] ?? null;

      console.log("Formatted Dates =>", startDate, endDate);
  
      const activityLogsResponse = await axios.get(`${API_URL}/activity_logs`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        params: {
          searchText: searchText || '', // Default to empty string if null
          start_date: startDate,
          end_date: endDate,
          page: page,
          page_size: pageSize,
          sort_order: sort,
        },
      });

      const {
        data,
        total_records,
        total_pages,
        current_page,
        page_size,
      } = activityLogsResponse.data;
  
      const jobsData = data.map(item => ({
        id: item.job_id,
        project_id: item.project_id,
        project_name: item.project_name,
        name: item.job_name,
        executed_time: item.job_executed_at,
      }));
      // console.log("Jobs Data -> ", jobsData)
      // console.log("date type=>", typeof jobsData[0].executed_time)
      return {
        jobs: jobsData,
        pagination: {
          totalRecords: total_records,
          totalPages: total_pages,
          currentPage: current_page,
          pageSize: page_size,
        },
      };
    } catch (error) {
      console.error('Error fetching activity logs:', error);
      throw error;
    }
  };
