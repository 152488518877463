import React, { useEffect, useState } from "react";
import {
  Input,
  Spin,
  Typography,
  Button,
  message,
  Popover,
  Table,
  Tooltip,
  DatePicker,
} from "antd";
import useFailedJobsData from "../hooks/useFailedJobsData";
import { fetchJobActivity } from "../utils/jobApi";
import { CopyOutlined, InfoCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const FailedJobsPage = () => {
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobActivity, setJobActivity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const pageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(-1);
  const {
    jobs,
    pagination,
    loading: jobLoading,
    refetchJobs,
  } = useFailedJobsData(currentPage, pageSize, searchText, selectedDates, sort); // Pass selectedDates to filter jobs
  const [rangePickerValue, setRangePickerValue] = useState();
  const [searchjobDetail, setSearchJobDetail] = useState("");
  const [originalJobActivity, setOriginalJobActivity] = useState([]);

  // Fetch job activity when job is selected
  useEffect(() => {
    if (selectedJob) {
      fetchJobActivityData();
    }
  }, [selectedJob]);

  // Automatically select the first job when the jobs are loaded
  useEffect(() => {
    if (jobs.length > 0 && !selectedJob) {
      setSelectedJob(jobs[0]); // Select the first job initially
    }
  }, [jobs, selectedJob]);

  const handleJobDetailSearchChange = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    console.log("searchQuery =>", searchQuery);
    setSearchJobDetail(searchQuery);

    if (!searchQuery) {
      setJobActivity(originalJobActivity);
      return;
    }

    const filteredJobActivity = originalJobActivity.filter((item) => {
      if (!item.request || typeof item.request !== "object") return false;

      return Object.values(item.request).some((value) =>
        String(value).toLowerCase().includes(searchQuery)
      );
    });

    setJobActivity(filteredJobActivity);
  };

  // Fetch job activity data
  const fetchJobActivityData = async () => {
    try {
      setLoading(true);
      const job_output = await fetchJobActivity(selectedJob.id);
      const job_activity = job_output
        .flatMap((item) =>
          item.jobs
            ? item.jobs.flatMap((job) =>
                job.media_data
                  ? job.media_data.map((media) =>
                      media.responses
                        ? media.responses.map((resp) => ({
                            job_name: selectedJob.name,
                            request: resp.request,
                            response: resp.response,
                          }))
                        : []
                    )
                  : []
              )
            : []
        )
        .flat();
      setJobActivity(job_activity);
      setOriginalJobActivity(job_activity);
    } catch (error) {
      message.error("Failed to fetch job activity.");
    } finally {
      setLoading(false);
    }
  };

  const handleJobClick = async (job) => {
    setSelectedJob(job);
  };

  const handleSort = (field) => {
    const newOrder = sort === 1 ? -1 : 1;
    // console.log("neworder => ", newOrder);
    // console.log("Sorting Order -> ", newOrder === 1 ? "Ascending" : "Descending");
    setSort(newOrder);
    setCurrentPage(1);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((error) => {
        message.error("Failed to copy.");
        console.error("Error copying to clipboard:", error);
      });
  };

  const findName = (obj) => {
    if (typeof obj !== "object" || obj === null) return null;
    if ("name" in obj) return obj.name;
    return Object.values(obj).reduce(
      (found, val) => found || findName(val),
      null
    );
  };

  const renderJobActivityTable = (list) => {
    const combinedList = (list || []).flat();

    const combinedListWithNames = combinedList.map((item) => ({
      ...item,
      name: findName(item) || "N/A",
    }));

    const dynamicColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (value) => {
          const isNepaliText = (text) => /[\u0900-\u097F]/.test(text);
          const fontFamily = isNepaliText(value) ? "monospace" : "";
          return (
            <span style={{ fontSize: "14px", fontFamily: fontFamily }}>
              {value || "N/A"}
            </span>
          );
        },
      },
      ...Object.keys(
        combinedListWithNames.reduce((acc, item) => ({ ...acc, ...item }), {})
      )
        .filter((key) => key !== "name" && key !== "job_name")
        .map((key) => ({
          title: key.charAt(0).toUpperCase() + key.slice(1),
          dataIndex: key,
          key,
          render: (value) => {
            if (typeof value === "object" && value !== null) {
              return (
                <Popover
                  title="Details"
                  content={
                    <div>
                      <pre style={{ margin: 0 }}>
                        {JSON.stringify(value, null, 2)}
                      </pre>
                      <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        onClick={() => handleCopy(JSON.stringify(value))}
                        style={{ marginTop: "10px" }}
                      >
                        Copy
                      </Button>
                    </div>
                  }
                  trigger="click"
                >
                  <Tooltip title="View Details">
                    <Button type="link" icon={<InfoCircleOutlined />} />
                  </Tooltip>
                </Popover>
              );
            }
            return value;
          },
        })),
    ];

    return (
      <div>
        <Spin spinning={loading}>
          {combinedListWithNames.length > 0 && (
            <Table
              columns={dynamicColumns}
              dataSource={combinedListWithNames}
              onRow={(record) => ({
                style: { cursor: "pointer" },
              })}
              rowKey={(record, index) => index}
              pagination={{
                pageSize: 10,
                showSizeChanger: false,
                showQuickJumper: false,
              }}
            />
          )}
        </Spin>
      </div>
    );
  };

  const jobColumns = [
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      render: (text) => (
        <span style={{ fontSize: "14px" }}>{text || "N/A"}</span>
      ),
    },
    {
      title: "Job Name",
      dataIndex: "name",
      key: "job_name",
      render: (name) => (
        <span style={{ fontSize: "14px" }}>{name || "N/A"}</span>
      ),
    },
    // {
    //   title: 'Created At',
    //   dataIndex: 'created_time',
    //   key: 'created_time',
    //   render: (time) => (
    //     <span style={{ fontSize: '12px' }}>
    //       {dayjs(time).format('YYYY-MM-DD HH:mm:ss') || 'N/A'}
    //     </span>
    //   ),
    //   sorter: (a, b) => new Date(a.executed_time) - new Date(b.executed_time),
    // },
    {
      title: "Executed At",
      dataIndex: "executed_time",
      key: "executed_time",
      render: (time) => (
        <span style={{ fontSize: "14px" }}>
          {time ? dayjs(time).format("YYYY-MM-DD HH:mm:ss") : ""}
        </span>
      ),
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => {
          // console.log("Sorting by Executed At");
          handleSort("executed_time");
        },
      }),
      // sorter: (a, b) => {
      //   if (!a.executed_time && !b.executed_time) return 0;
      //   if (!a.executed_time) return -1;
      //   if (!b.executed_time) return 1;
      //   return new Date(a.executed_time) - new Date(b.executed_time);
      // },
    },
  ];

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Trigger fetch on search text change
  const handleSearchChange = (e) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    setCurrentPage(1); // Reset page when search text changes
  };

  // Handle date range change
  const handleDateChange = (dates, dateStrings) => {
    console.log("DATES -> ", dateStrings);

    const rangePickerValue = [
      dateStrings[0] ? dayjs(dateStrings[0]) : null,
      dateStrings[1] ? dayjs(dateStrings[1]) : null,
    ];

    setRangePickerValue(rangePickerValue);

    setSelectedDates(dateStrings);
    setCurrentPage(1); // Reset page when date range changes
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        padding: "20px",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div
        style={{
          width: "40%",
          marginRight: "20px",
          backgroundColor: "#fff",
          padding: "15px",
          overflowY: "auto",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Input
            placeholder="Search Project"
            value={searchText}
            onChange={handleSearchChange}
            style={{
              marginRight: 10,
            }}
          />
          <RangePicker value={rangePickerValue} onChange={handleDateChange} />
        </div>
        <Spin spinning={jobLoading}>
          <Table
            columns={jobColumns}
            dataSource={jobs}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleJobClick(record),
              style: { cursor: "pointer" },
            })}
            pagination={{
              current: currentPage,
              pageSize: pagination.pageSize,
              total: pagination.totalRecords,
              onChange: handlePageChange,
              style: {
                display: "flex",
                justifyContent: "center",
              },
            }}
          />
        </Spin>
      </div>

      <div
        style={{
          width: "60%",
          backgroundColor: "#fff",
          padding: "15px",
          overflowY: "auto",
          // borderLeft: '1px solid #e0e0e0',
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <Title level={5} style={{ marginRight: 10 }}>
            Job Details for {selectedJob?.name ?? "N/A"}
          </Title>
          <Input
            placeholder="Search"
            value={searchjobDetail}
            onChange={handleJobDetailSearchChange}
            style={{ flexGrow: 1, marginLeft: 100 }}
          />
        </div>

        {renderJobActivityTable(jobActivity)}
      </div>
    </div>
  );
};

export default FailedJobsPage;
