import axios from "axios";
import { getAccessToken } from "./auth"; // Ensure you use the correct getAccessToken function

import { API_URL } from "../utils/jobApi";

// Flag to switch between dummy data and real API
const USE_DUMMY_DATA = false;

// Dummy data for select options
const dummySchoolNames = [
  "Greenwood High School",
  "Riverside Elementary",
  "Mountainview Secondary",
  "Lakeside Academy",
  "Sunnydale High",
  "Hillcrest College",
  "Maplewood Institute",
  "Oakridge School",
  "Pinecrest High",
  "Willowbrook Elementary",
];

const dummyScholarshipSchemes = [
  "Merit Scholarship",
  "Need-Based Scholarship",
  "Athletic Scholarship",
  "Arts Scholarship",
  "Community Service Scholarship",
  "STEM Scholarship",
  "International Scholarship",
  "Leadership Scholarship",
  "Diversity Scholarship",
  "Environmental Scholarship",
];

const dummySubjects = [
  "Mathematics",
  "English Literature",
  "Biology",
  "Chemistry",
  "Physics",
  "History",
  "Geography",
  "Computer Science",
  "Economics",
  "Art",
];

const dummyPrincipals = [
  "John Smith",
  "Mary Johnson",
  "Robert Brown",
  "Patricia Taylor",
  "Michael Williams",
  "Linda Davis",
  "William Jones",
  "Barbara Wilson",
  "David Miller",
  "Elizabeth Moore",
];

const dummyDistricts = [
  "Central District",
  "North District",
  "South District",
  "East District",
  "West District",
  "Uptown District",
  "Downtown District",
  "Suburban District",
  "Rural District",
  "Coastal District",
];
const dummyPackageSchemes = [
  "Scholarship 1",
  "Scholarship 2",
  "Scholarship 3",
  "Scholarship 4",
  "Scholarship 5",
  "Scholarship 6",
  "Scholarship 7",
  "Scholarship 8",
  "Scholarship 9",
  "Scholarship 10",
];
// Function to get options data, either from dummy data or real API
export const getOptionsData = async () => {
  if (USE_DUMMY_DATA) {
    // Return dummy data
    return {
      schoolNames: dummySchoolNames,
      scholarshipSchemes: dummyScholarshipSchemes,
      subjects: dummySubjects,
      principals: dummyPrincipals,
      districts: dummyDistricts,
      packageSchemes: dummyScholarshipSchemes,
    };
  } else {
    // Fetch real data from API
    try {
      const token = getAccessToken(); // Get token from localStorage

      if (!token) {
        throw new Error("User is not authenticated. Please log in.");
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      // Replace the following API calls with actual endpoints
      const [schoolNamesRes, scholarshipSchemesRes] = await Promise.all([
        axios.get(`${API_URL}/agdata/options/schools`, { headers }),
        axios.get(`${API_URL}/agdata/options/scholarships`, { headers }),
      ]);
      const schoolNames = schoolNamesRes.data.data.map((school) => school.name);
      const scholarshipNames = scholarshipSchemesRes.data.data.map(
        (scholarship) => scholarship.name
      );
      const districts = schoolNamesRes.data.data.map(
        (school) => school.district_name
      );
      const status = scholarshipSchemesRes.data.data.map(
        (scholarship) => scholarship.status
      );
      const cities = schoolNamesRes.data.data.map((school) => school.city_name);
      const districtId = schoolNamesRes.data.data.map(
        (school) => school.district_id
      );
      const cityID = schoolNamesRes.data.data.map((school) => school.city_id);
      const packageSchemes = scholarshipSchemesRes.data.data;

      return {
        schoolNames,
        scholarshipSchemes: scholarshipNames,
        districts,
        status,
        cities,
        districtId,
        cityID,

        packageSchemes,
      };
    } catch (error) {
      console.error("Error fetching options data:", error);
      throw error;
    }
  }
};

export const submitDefaults = async (values) => {
  // For now, simulate API call with a dummy response
  try {
    // Uncomment and modify the following code when the API is ready
    /*
    const token = getAccessToken();  // Get token from localStorage

    if (!token) {
      throw new Error('User is not authenticated. Please log in.');
    }

    const response = await axios.post(`${API_URL}/setdefaults/<project id goes here>`, values, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;  // Assuming API response contains { success: true/false, message }
    */

    // Dummy response for simulation
    return Promise.resolve({
      success: true,
      message: "Defaults submitted successfully",
    });
  } catch (error) {
    console.error("Error submitting defaults:", error);
    throw error;
  }
};

// Create a project
export const createProject = async (project) => {
  try {
    const token = getAccessToken(); // Get token from localStorage

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.post(`${API_URL}/projects/create`, project, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data; // Assuming API response contains { success: true/false, msg, project }
  } catch (error) {
    console.error("Error creating project:", error);
    return {
      success: false,
      message: "An error occurred while creating the project",
    };
  }
};

// Edit a project
export const editProject = async (project, editProjectId) => {
  console.log("Project and editProjectId -> ", { project, editProjectId });
  try {
    const token = getAccessToken(); // Get token from localStorage

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.put(
      `${API_URL}/projects/${editProjectId}`,
      project,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    return response.data; // Assuming API response contains { success: true/false, msg, project }
  } catch (error) {
    console.error("Error editing project:", error);
    return {
      success: false,
      message: "An error occurred while editing the project",
    };
  }
};

// Fetch all projects
export const fetchProjects = async () => {
  try {
    const token = getAccessToken(); // Use correct accessToken here

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.get(`${API_URL}/projects`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    return response.data; // Ensure you're returning the data
  } catch (error) {
    console.error("Error fetching projects:", error);
    throw error; // Re-throw error so that caller can handle it
  }
};

export const deleteProject = async (projectId) => {
  try {
    const token = getAccessToken(); // Use correct accessToken here
    const response = await axios.delete(`${API_URL}/projects/${projectId}`, {
      headers: {
        accept: "application/json", // Set the Accept header
        Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });
    return response.data; // Return the deletion result
  } catch (error) {
    throw new Error("Failed to delete project");
  }
};

export const fetchSchoolNames = async (searchQuery) => {
  try {
    const token = getAccessToken(); // Get token from localStorage

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    // const params = {
    //   query: searchQuery,
    // };

    // Make sure your backend API accepts 'query' as a parameter
    const response = await axios.get(
      `${API_URL}/agdata/options/schools/${searchQuery}`,
      {
        headers,
        // params,
      }
    );

    return response.data; // Assuming the API returns data in { data: [...] } format
  } catch (error) {
    console.error("Error fetching school names:", error);
    throw error;
  }
};
