// src/pages/AssignJobs.js

import React from "react";
import { Layout, Row, Col, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import useAssignJobsData from "../hooks/useAssignJobsData";
import useAssignJobsLogic from "../hooks/useAssignJobsLogic";
import SelectJobs from "../components/SelectJobs";
import SelectedJobs from "../components/SelectedJobs";
import SelectAgent from "../components/SelectAgent";
import AssignmentSummary from "../components/AssignmentSummary";
import PendingAssignmentsDrawer from "../components/PendingAssignmentsDrawer";

const { Content } = Layout;

/**
 * AssignJobs Component
 * Main page component for assigning jobs to agents.
 */
const AssignJobs = () => {
  // Fetch data using custom hooks
  const { projectsData, agentsData, initialAssignedJobs, loading } =
    useAssignJobsData();

  // Manage logic and state using custom hook
  const {
    selectedProjects,
    handleProjectSelect,
    verificationFilter,
    handleVerificationFilterChange,
    handleJobSearchChange,
    filteredJobs,
    handleJobRowClick,
    rowClassName,
    jobColumns,
    selectedJobs,
    getJob,
    handleRemoveSelectedJob,
    selectedAgentId,
    handleAgentSelect,
    searchAgentText,
    setSearchAgentText,
    handleAssignJobs,
    assignedJobs,
    getAgent,
    handleRemoveAssignedJobs,
    searchSummaryText,
    setSearchSummaryText,
    pendingAssignments,
    setIsDrawerVisible,
    isDrawerVisible,
    handleRemovePendingAssignment,
    handleConfirmAssignments,
  } = useAssignJobsLogic(projectsData, agentsData, initialAssignedJobs);

  return (
    <Layout style={{ height: "100vh" }}>
      <Content
        style={{
          padding: "24px",
          background: "#f0f2f5",
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        {/* Three-Column Layout */}
        <Row gutter={[16, 16]}>
          {/* Select Jobs */}
          <Col xs={24} sm={24} md={8}>
            <SelectJobs
              projectsData={projectsData}
              selectedProjects={selectedProjects}
              handleProjectSelect={handleProjectSelect}
              verificationFilter={verificationFilter}
              handleVerificationFilterChange={handleVerificationFilterChange}
              handleJobSearchChange={handleJobSearchChange}
              filteredJobs={filteredJobs}
              handleJobRowClick={handleJobRowClick}
              rowClassName={rowClassName}
              jobColumns={jobColumns}
            />
          </Col>

          {/* Selected Jobs */}
          <Col xs={24} sm={24} md={8}>
            <SelectedJobs
              selectedJobs={selectedJobs}
              getJobById={getJob}
              handleRemoveSelectedJob={handleRemoveSelectedJob}
            />
          </Col>

          {/* Select Agent and Assign Button */}
          <Col xs={24} sm={24} md={8}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <SelectAgent
                agentsData={agentsData}
                selectedAgentId={selectedAgentId}
                handleAgentSelect={handleAgentSelect}
                searchAgentText={searchAgentText}
                setSearchAgentText={setSearchAgentText}
                loading={loading}
              />
              <Button
                type="primary"
                icon={<ArrowRightOutlined />}
                onClick={handleAssignJobs}
                disabled={selectedJobs.length === 0 || !selectedAgentId}
                block
                style={{ marginTop: "10px" }}
              >
                Assign to Agent
              </Button>
            </div>
          </Col>
        </Row>

        {/* Assignment Summary */}
        <AssignmentSummary
          assignedJobs={assignedJobs}
          getAgentById={getAgent}
          getJobById={getJob}
          handleRemoveAssignedJobs={handleRemoveAssignedJobs}
          searchSummaryText={searchSummaryText}
          setSearchSummaryText={setSearchSummaryText}
          pendingAssignments={pendingAssignments}
          setIsDrawerVisible={setIsDrawerVisible}
        />

        {/* Pending Assignments Drawer */}
        <PendingAssignmentsDrawer
          isDrawerVisible={isDrawerVisible}
          setIsDrawerVisible={setIsDrawerVisible}
          pendingAssignments={pendingAssignments}
          getJobById={getJob}
          getAgentById={getAgent}
          handleRemovePendingAssignment={handleRemovePendingAssignment}
          handleConfirmAssignments={handleConfirmAssignments}
        />
      </Content>

      {/* Custom Styles */}
      <style jsx="true">{`
        .selected-row {
          background-color: #e6f7ff !important;
        }
        .ant-table-tbody > tr:hover > td {
          cursor: pointer;
        }
        .ant-list-items {
          scrollbar-width: thin;
          scrollbar-color: #1890ff transparent;
        }
        .ant-list-items::-webkit-scrollbar {
          width: 6px;
        }
        .ant-list-items::-webkit-scrollbar-track {
          background: transparent;
        }
        .ant-list-items::-webkit-scrollbar-thumb {
          background-color: #1890ff;
          border-radius: 3px;
        }
      `}</style>
    </Layout>
  );
};

export default AssignJobs;
