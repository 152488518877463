// src/pages/AdminDashboard.js

import React, { useState } from 'react';
import { Layout, Row, Col, Card, Tabs, List, Avatar, Progress, Spin, Statistic, Input } from 'antd';
import {
  UserOutlined,
  TeamOutlined,
  SafetyCertificateOutlined,
} from '@ant-design/icons';
import useAdminDashboardData from '../hooks/useAdminDashboardData';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

const AdminDashboard = () => {
  const {
    userCounts,
    verifiedJobCounts,
    jobStatusCounts,
    unassignedJobsCount,
    agentsPerformanceData,
    supervisorsAssignmentsData,
    loading,
    loadingAgentsData,
    loadingSupervisorsData,
  } = useAdminDashboardData();

  const [activeTabKey, setActiveTabKey] = useState('agents');
  const [agentSearchText, setAgentSearchText] = useState('');
  const [supervisorSearchText, setSupervisorSearchText] = useState('');

  if (loading) {
    return (
      <Layout>
        <Content style={{ padding: '24px' }}>
          <Spin />
        </Content>
      </Layout>
    );
  }

  const totalJobs = Object.values(jobStatusCounts).reduce((sum, count) => sum + count, 0);
  const totalUsers = userCounts.agents + userCounts.supervisors + userCounts.admins;

  // Handlers for search
  const handleAgentSearch = (value) => {
    setAgentSearchText(value);
  };

  const handleSupervisorSearch = (value) => {
    setSupervisorSearchText(value);
  };

  // Filtered agents data
  // const filteredAgentsData = agentsPerformanceData.filter((agent) => {
  //   return agent.name.toLowerCase().includes(agentSearchText.toLowerCase());
  //   // console.log(agent)
  //   // return agent
  // }
  // );
  const filteredAgentsData = agentsPerformanceData.filter((agent) => {
  return agent && agent.name && agent.name.toLowerCase().includes(agentSearchText.toLowerCase());
});


  // Filtered supervisors data
const filteredSupervisorsData = supervisorsAssignmentsData.filter((supervisor) => {
  return supervisor && supervisor.name && supervisor.name.toLowerCase().includes(supervisorSearchText.toLowerCase());
});

  return (
    <Layout>
      <Content style={{ padding: '24px' }}>
        <Row gutter={[16, 16]}>
          {/* Users Overview Card */}
          <Col xs={24} md={8}>
            <Card title="Users Overview">
              <p>Total Users: {totalUsers}</p>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Statistic
                    title="Agents"
                    value={userCounts.agents}
                    prefix={<UserOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Supervisors"
                    value={userCounts.supervisors}
                    prefix={<TeamOutlined />}
                  />
                </Col>
                <Col span={8}>
                  <Statistic
                    title="Admins"
                    value={userCounts.admins}
                    prefix={<SafetyCertificateOutlined />}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          {/* Job Status Card */}
          <Col xs={24} md={8}>
            <Card title="Jobs by Status">
              <p>Total Jobs: {totalJobs}</p>
              <Progress
                percent={(jobStatusCounts.pending / totalJobs) * 100}
                status="warning"
                format={() => `Pending: ${jobStatusCounts.pending}`}
                strokeColor="#757575"
              />
              <Progress
                percent={(jobStatusCounts['in-progress'] / totalJobs) * 100}
                status="active"
                format={() => `In Progress: ${jobStatusCounts['in-progress']}`}
                strokeColor="#1E88E5"
              />
              <Progress
                percent={(jobStatusCounts.completed / totalJobs) * 100}
                status="success"
                format={() => `Completed: ${jobStatusCounts.completed}`}
                strokeColor="#4CAF50"
              />
              <Progress
                percent={(jobStatusCounts.failed / totalJobs) * 100}
                status="exception"
                format={() => `Failed: ${jobStatusCounts.failed}`}
                strokeColor="#f5222d"
              />
              <Progress
                percent={(unassignedJobsCount / totalJobs) * 100}
                status="warning"
                format={() => `Unassigned: ${unassignedJobsCount}`}
                strokeColor="#FDD835"
              />
            </Card>
          </Col>

          {/* Verification Status Card */}
          <Col xs={24} md={8}>
            <Card title="Job Verification">
              <p>Total Jobs: {totalJobs}</p>
              <Progress
                percent={(verifiedJobCounts.verified / totalJobs) * 100}
                status="success"
                format={() => `Verified: ${verifiedJobCounts.verified}`}
                strokeColor="#4CAF50"
              />
              <Progress
                percent={(verifiedJobCounts.non_verified / totalJobs) * 100}
                status="exception"
                format={() => `Non-Verified: ${verifiedJobCounts.non_verified}`}
                strokeColor="#FDD835"
              />
            </Card>
          </Col>
        </Row>

        {/* Tabs Section */}
        <Card style={{marginTop:20, padding: 5}}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
              <TabPane tab="Agents Performance" key="agents">
                <Search
                  placeholder="Search agents"
                  onSearch={handleAgentSearch}
                  style={{ width: 300, marginBottom: '16px' }}
                  allowClear
                />
                {loadingAgentsData ? (
                  <Spin />
                ) : (
                  <Card>
                    <List
                      itemLayout="horizontal"
                      dataSource={filteredAgentsData}
                      renderItem={(agent) => (
                        <>
                        <List.Item>
                          <List.Item.Meta
                            avatar={<Avatar icon={<UserOutlined />} />}
                            title={agent.name}
                            description={`Total Jobs: ${agent.totalJobs}, Verified Jobs: ${agent.verifiedJobs}`}
                          />
                          <Progress
                            percent={agent.totalJobs > 0 ? (agent.verifiedJobs / agent.totalJobs) * 100 : 0}
                            status="active"
                            style={{ width: '200px' }}
                          />
                        </List.Item>
                        </>
                      )}
                    />
                  </Card>
                )}
              </TabPane>
              <TabPane tab="Assignments by Supervisors/Admins" key="supervisors">
  <Search
    placeholder="Search supervisors/admins"
    onSearch={handleSupervisorSearch}
    style={{ width: 300, marginBottom: '16px' }}
    allowClear
  />
  {loadingSupervisorsData ? (
    <Spin />
  ) : (
    <Card>
      <List
        itemLayout="horizontal"
        dataSource={filteredSupervisorsData}
        renderItem={(user) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<SafetyCertificateOutlined />} />}
              title={user.name}
              description={`Role: ${user.role}`} // You can customize this line as needed
            />
            <Statistic
              title="Assigned Jobs"
              value={user.assignedJobs}
              valueStyle={{ color: '#1890ff', fontWeight: 'bold' }}
              prefix={<SafetyCertificateOutlined />}
            />
          </List.Item>
        )}
      />
    </Card>
  )}
</TabPane>
            </Tabs>
          </Col>
        </Row>
        </Card>
      </Content>
    </Layout>
  );
};

export default AdminDashboard;
