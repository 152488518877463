import axios from "axios";
import { getAccessToken } from "./auth"; // Adjust the path if needed
import { API_URL } from "../utils/jobApi";

export const fetchAllSubordinates = async (
  pageNumber = 1,
  documentsPerPage = 10,
  searchKeywords,
  selectedRole
) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.get(`${API_URL}/users/subordinates`, {
      params: {
        page_number: pageNumber,
        documents_per_page: documentsPerPage,
        agent_search_query: searchKeywords,
        role_filter: selectedRole,
      },
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return {
      subordinates: response.data.documents,
      totalCount: response.data.total_documents,
      currentPage: response.data.current_page,
      totalPages: response.data.total_pages,
    };
  } catch (error) {
    console.error("Error fetching subordinates:", error);
    throw error;
  }
};

export const resetSubordinatePassword = async (subordinate_id) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.post(
      `${API_URL}/users/reset_password`,
      { subordinate_id },
      {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error resetting subordinate password:", error);
    throw error;
  }
};

export const changeSubordinateRole = async (subordinate_id, new_role) => {
  try {
    const token = getAccessToken();

    if (!token) {
      throw new Error("User is not authenticated. Please log in.");
    }

    const response = await axios.post(`${API_URL}/users/change_role`, "", {
      params: {
        subordinate_id,
        new_role,
      },
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error changing subordinate role:", error);
    throw error;
  }
};
